import revive_payload_client_g9uG75g3GD from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.8.0_ioredis@5.4.1_magicast@0._g7bqyw44dtrqsutmbne4ppbplq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_SkdjUSAivR from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.8.0_ioredis@5.4.1_magicast@0._g7bqyw44dtrqsutmbne4ppbplq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PDY4rY7Hs7 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.8.0_ioredis@5.4.1_magicast@0._g7bqyw44dtrqsutmbne4ppbplq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_pPF2ZfheZx from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.8.0_ioredis@5.4.1_magicast@0._g7bqyw44dtrqsutmbne4ppbplq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_9Pi0mLPNaE from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.8.0_ioredis@5.4.1_magicast@0._g7bqyw44dtrqsutmbne4ppbplq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_EyEbMu9kGv from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.8.0_ioredis@5.4.1_magicast@0._g7bqyw44dtrqsutmbne4ppbplq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bIUxXS1AYG from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.8.0_ioredis@5.4.1_magicast@0._g7bqyw44dtrqsutmbne4ppbplq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_u47NSGCa3g from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.5.8_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_yyMZfOC2au from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.8.0_ioredis@5.4.1_magicast@0._g7bqyw44dtrqsutmbne4ppbplq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wlrkXSiQaB from "/app/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.5.8_typescript@5.5.3_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_ZOf3UBM5YC from "/app/node_modules/.pnpm/nuxt-echarts@0.1.0_echarts@5.5.1_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import plugin_0eqJTh028b from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+core@7.24.7_@ope_mh3dxmbzetj4add6aof4g7gryy/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_3jZqWBC7se from "/app/node_modules/.pnpm/@primevue+nuxt-module@4.0.3_@babel+parser@7.25.6_magicast@0.3.4_rollup@4.18.1_vue@3.5.8_typescript@5.5.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_g6gbxqQzbU from "/app/node_modules/.pnpm/@nuxt+icon@1.4.4_magicast@0.3.4_rollup@4.18.1_vite@5.3.4_@types+node@20.14.10_terser@5.31.2__vue@3.5.8_typescript@5.5.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_QCjTg8gLeU from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.18.1_typesc_hcuqijcr7m6gjadmjxhtguducu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import sentry_client_a4hAB0MOGi from "/app/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_g9uG75g3GD,
  unhead_SkdjUSAivR,
  router_PDY4rY7Hs7,
  payload_client_pPF2ZfheZx,
  navigation_repaint_client_9Pi0mLPNaE,
  check_outdated_build_client_EyEbMu9kGv,
  chunk_reload_client_bIUxXS1AYG,
  plugin_vue3_u47NSGCa3g,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yyMZfOC2au,
  plugin_wlrkXSiQaB,
  plugin_ZOf3UBM5YC,
  plugin_0eqJTh028b,
  primevue_plugin_egKpok8Auk,
  plugin_client_3jZqWBC7se,
  plugin_g6gbxqQzbU,
  plugin_QCjTg8gLeU,
  sentry_client_a4hAB0MOGi
]