export default {
    root: ({ attrs }) => ({
        class: [
            // Shape
            attrs.tooltip ? 'rounded' : 'rounded-md',
            'shadow-lg',

            // Position
            'absolute left-0 top-0 mt-2',
            'z-40 transform origin-center',

            // Color
            attrs.tooltip
                ? 'bg-neutral-700 text-white'
                : 'bg-surface-0 dark:bg-surface-900 text-surface-700 dark:text-surface-0/80',

            // Before: Arrow
            'before:absolute before:w-0 before:h-0 before:border-transparent before:border-solid before:ml-[10px] before:border-x-[10px] before:border-b-[10px] before:border-t-0',
            'after:absolute after:w-0 after:-top-[0.54rem] after:left-[4px] after:h-0 after:border-transparent after:border-solid after:ml-[8px] after:border-x-[8px] after:border-b-[8px] after:border-t-0',
            attrs.tooltip
                ? 'before:-top-1 before:border-b-neutral-700 after:border-b-neutral-0 before:right-4'
                : 'before:-top-3 before:border-b-surface-200 dark:before:border-b-surface-700 after:border-b-surface-0 dark:after:border-b-surface-900',
        ],
    }),
    content: ({ attrs }) => ({
        class: [
            'items-center flex',
            attrs.tooltip ? 'p-2 text-sm' : 'p-5 rounded-lg border border-surface-200 dark:border-surface-700',
        ],
    }),
    transition: {
        enterFromClass: 'opacity-0 scale-y-[0.8]',
        enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-100 ease-linear',
        leaveToClass: 'opacity-0',
    },
};
